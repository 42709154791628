































import Vue from "vue";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "select-playlist-modal" as string,
  data() {
    return {
      i18: i18n
    }
  },
  props: {
    data: Object,
  },
  methods: {
    UpdateVenuePlaylist() {
      if(this.data.venue_type_id){
        this.$emit('setVenuePlaylist', this.data);
      }
      this.$bvModal.hide("playlist-modal");
    },
    setEnergy(energy) {
      if(energy == 1){
        return this.$t('Low')
      }
      if(energy == 2){
        return this.$t('Low to medium')
      }
      if(energy == 3){
        return this.$t('Low to high')
      }
      if(energy == 4){
        return this.$t('Medium')
      }
      if(energy == 5){
        return this.$t('High')
      }
    }
  },


  /*watch: {
    value(value) {
      if (value) {
        this.bankIban = value.iban;
        this.bankSwiftCode = value.swiftCode;
        this.bankName = value.bankName;
      } else {
        this.bankIban = "";
        this.bankSwiftCode = "";
        this.bankName = "";
      }
    }
  }*/
})
