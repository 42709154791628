






































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import ActivePlaylist from "@/components/venue-detail/ActivePlaylist.vue";
import SelectPlaylistModal from "@/components/modals/SelectPlaylistModal.vue";
import BackButton from "@/components/back-button.vue";
import {mapActions, mapGetters} from "vuex";
import {VenueTypesData} from "@/types";
import {successMessage} from "@/utils/messages-handlers";
import router from "@/router";

export default Vue.extend({
  name: "select-playlist" as string,

  components: {
    Layout,
    ActivePlaylist,
    "select-playlist-modal": SelectPlaylistModal,
    "back-button": BackButton
  },

  data() {
    return {
      title: "Select your playlist",
      genre: "Rock / Pop / New Wave",
      selectedPlaylist: {},
      markActive:true,

    }
  },

  async mounted(): Promise<void> {
    await this.setVenueTypes();
    this.fetchPlaylistsImages();
    await this.fetchCustomPlaylist({venue_id: localStorage.getItem("venue_id")});
    await this.setVenue({venue_id: localStorage.getItem("venue_id")});
  },

  computed: {
    ...mapGetters("playlistModule", {
      venueTypes: "GET_VENUE_TYPES",
      venue: "GET_SELECTED_VENUE",
      customPlaylist: "GET_CUSTOM_PLAYLIST",
    }),
  },
  methods: {
    ...mapActions("playlistModule", {
      setVenueTypes: "FETCH_VENUE_TYPES",
      setVenue: "FETCH_VENUE",
      updateVenueData: "UPDATE_VENUE",
      fetchCustomPlaylist: "FETCH_CUSTOM_PLAYLIST",
      setCustomPlaylist: "SET_CUSTOM_PLAYLIST",
      disableCustomPlaylist: "DISABLE_CUSTOM_PLAYLIST",
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    insertPlaylistImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    fetchPlaylistsImages(): void {
      for (let i = 0; i < this.venueTypes.length; i++) {
        const type: VenueTypesData = this.venueTypes[i];

        if (!!type.image_last_updated) {
          this.setImage({id: type.venue_type_id, imageType: "venue_type", multi: true});
        }
      }
    },
    openPlaylistModal(value: object): void {
      this.selectedPlaylist = value;
      this.$bvModal.show("playlist-modal");
    },
    goGenerateCustomPlaylist() {
      this.$router.push("/select-genres");
    },
    async setVenuePlaylist(data): Promise<void> {
      this.markActive = false;
      const venuePayloadID = {};
      if(data.venue_type_alias_id){
        venuePayloadID["venue_type_alias_id"] = data.venue_type_alias_id;
      }
      if(!data.venue_type_alias_id){
        venuePayloadID["venue_type_id"] = data.venue_type_id;
      }
      await this.updateVenueData(venuePayloadID);
      if(data.name !== 'Custom' && this.customPlaylist.active){
        await this.disableCustomPlaylist();
      }
      if(data.name == 'Custom'){
        await this.setCustomPlaylist({
          censor_genre_ids: this.customPlaylist.censor_genre_ids
        })
      }
      this.markActive = true;
      this.fetchCustomPlaylist({venue_id: localStorage.getItem("venue_id")});
      successMessage('Playlist changed', 3000);
    },
    checkSelectedPlaylist(data) {
      if (!this.markActive) return false;
      if (data.venue_type_alias_id) {
        if (this.venue.venue_type_id === data.venue_type_id && this.venue.venue_type_alias_id === data.venue_type_alias_id) {
          return true
        }
      }
      if (!data.venue_type_alias_id) {
        if (this.venue.venue_type_id === data.venue_type_id && this.venue.venue_type_alias_id === data.venue_type_alias_id) {
          return true
        }
      }
    },
  }
})
