











import Vue, {PropType} from "vue";
export default Vue.extend({

  props: {
    title: String as PropType<string>,
    destination: String as PropType<string>,
    params: String as PropType<string>,
  },

  data() {
    return {};
  },
  methods: {
    goBack(){
      if(this.params){
        this.$router.push({
          path: this.destination + this.params,
          params: { id: this.params }
        })
      } else{
        this.$router.push(this.destination);
      }
    }
  }
});
