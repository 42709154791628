


























import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import { VenueData, VenueTypesData } from "@/types";

export default Vue.extend({
  name: "active-playlist" as string,

  props: {
    edit: Boolean as PropType<boolean>,
    hasPermissions: Boolean as PropType<boolean>,
    venue: Object as PropType<VenueData>
  },

  async mounted(): Promise<void> {
    await this.setVenueTypes();
    await this.setVenue({venue_id: localStorage.getItem("venue_id")});
    this.fetchPlaylistsImages();
    await this.setImage({id: this.venue.venue_type_id, imageType: "venue_type"});
  },

  computed: {
    ...mapGetters("playlistModule", {
      venueTypes: "GET_VENUE_TYPES",
      venue: "GET_SELECTED_VENUE",
    }),
    Playlist(){
      if(!this.venueTypes.some(x => x.venue_type_id === this.venue.venue_type_id)){
        return 'Custom Playlist'
      } else{
      return this.venueTypes
      .filter(x => x.venue_type_id === this.venue.venue_type_id)
      .filter(x => x.venue_type_alias_id === this.venue.venue_type_alias_id)[0].name;
      }
    }
  },

  methods: {
    ...mapActions("playlistModule", {
      setVenueTypes: "FETCH_VENUE_TYPES",
      setVenue: "FETCH_VENUE",
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    insertPlaylistImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    fetchPlaylistsImages(): void {
      for (let i = 0; i < this.venueTypes.length; i++) {
        const type: VenueTypesData = this.venueTypes[i];

        if (!!type.image_last_updated) {
          this.setImage({id: type.venue_type_id, imageType: "venue_type", multi: true});
        }
      }
    },
    goEditPlaylist() {
      if (!this.hasPermissions) return;
      this.$router.push("/select-playlist");
    }
  }
})
